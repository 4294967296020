import Dayjs from 'dayjs';

const now = Dayjs();
const launchStart = process.env.REACT_APP_LAUNCH_START;
const launchEnd = process.env.REACT_APP_LAUNCH_END;

export const eventEnded = () => {
  if (now.isSame(Dayjs(launchEnd)) || now.isAfter(Dayjs(launchEnd))) {
    return true;
  }
  return false;
};

export const eventNotStarted = () => {
  return now.isBefore(Dayjs(launchStart));
};
