// eslint-disable @typescript-eslint/dot-notation
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, RouteObject, redirect } from 'react-router-dom';

import { AppLanguage, AppRoute } from '../const';
import AVAILABLE_LOCALES from '../const/available-locales';
import { eventEnded } from '../utils/date-time/launchHelper';
import { appStrings } from '../modules/i18n';
import LoginRedirect from './LoginRedirect';
import Layout from '../pages/layout';
import LanguageRedirect from './LanguageRedirect';

import {
  LoginPage,
  LoginSSORedirect,
  ErrorPage,
  ResetPasswordPage,
  RequestResetPasswordPage
} from '../pages';

import {
  AccessibilityStatement,
  CookiePolicy,
  PrivacyPolicy,
  TermsAndConditions
} from '../pages/footer-links';

import {
  LandingPage,
  ContactPage,
  AboutPage,
  LaunchEventPage,
  LaunchEventPageCy
} from '../pages/landing-pages';

import LandingLayout from '../pages/LandingLayout';

const landingPages = AVAILABLE_LOCALES.map((lang) => {
  return [
    {
      index: true,
      element: <LandingPage />,
      errorElement: <ErrorPage />
    },
    {
      path: appStrings[lang][AppRoute.Contact],
      element: <ContactPage />,
      errorElement: <ErrorPage />
    },
    {
      path: appStrings[lang][AppRoute.About],
      element: <AboutPage />,
      errorElement: <ErrorPage />
    }
  ];
}).flat();

const eventLandingPages = AVAILABLE_LOCALES.map((lang) => {
  return [
    {
      path: appStrings[lang][AppRoute.LaunchEvent],
      element: !eventEnded() ? (
        <LaunchEventPage />
      ) : (
        <Navigate replace to="/" />
      ),
      errorElement: <ErrorPage />
    },
    {
      path: appStrings[lang][AppRoute.LaunchEventCy],
      element: !eventEnded() ? (
        <LaunchEventPageCy />
      ) : (
        <Navigate replace to="/" />
      ),
      errorElement: <ErrorPage />
    }
  ];
}).flat();

const footerPages = AVAILABLE_LOCALES.map((lang) => {
  return [
    {
      path: appStrings[lang][AppRoute.AccessibilityStatement],
      element: <AccessibilityStatement />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => (
          <FormattedMessage
            id="accessibility_statement.breadcrumb"
            defaultMessage="Accessibility Statement"
            description="Accessibility Statement"
          />
        )
      }
    },
    {
      path: appStrings[lang][AppRoute.CookiePolicy],
      element: <CookiePolicy />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => (
          <FormattedMessage
            id="cookie_policy.breadcrumb"
            defaultMessage="Cookie Policy"
            description="Cookie Policy"
          />
        )
      }
    },
    {
      path: appStrings[lang][AppRoute.PrivacyPolicy],
      element: <PrivacyPolicy />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => (
          <FormattedMessage
            id="privacy_policy.breadcrumb"
            defaultMessage="Privacy Policy"
            description="Privacy Policy"
          />
        )
      }
    },
    {
      path: appStrings[lang][AppRoute.TermsAndConditions],
      element: <TermsAndConditions />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => (
          <FormattedMessage
            id="terms_and_conditions.breadcrumb"
            defaultMessage="Terms and Conditions"
            description="Terms and Conditions"
          />
        )
      }
    }
  ];
}).flat();

const appPages = AVAILABLE_LOCALES.map((lang) => {
  return [
    {
      path: appStrings[lang][AppRoute.Login],
      element: <LoginPage />,
      errorElement: <ErrorPage />
    },
    {
      path: appStrings[lang][AppRoute.ForgottenPassword],
      element: <ResetPasswordPage />,
      errorElement: <ErrorPage />
    },
    {
      path: appStrings[lang][AppRoute.RequestForgottenPassword],
      element: <RequestResetPasswordPage />,
      errorElement: <ErrorPage />
    },

    {
      path: `${appStrings[lang][AppRoute.LoginSSORedirect]}/:guid`,
      element: <LoginSSORedirect />,
      errorElement: <ErrorPage />
    },
    {
      path: appStrings[lang][AppRoute.Login],
      element: <Navigate to="/" />,
      errorElement: <ErrorPage />
    }
  ];
}).flat();

export default function routes(
  defaultLanguage: AppLanguage,
  loggedIn?: boolean
): RouteObject {
  let currentLang = defaultLanguage;

  const loader = ({ params }: { params: any }) => {
    const { lang } = params;
    // check if lang is one we support
    if (AVAILABLE_LOCALES.includes(lang)) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      currentLang = params['lang'];
    } else {
      console.warn(
        `${lang} is NOT a valid language, redirecting to default lang...`
      );
      return redirect(`/${currentLang || AppLanguage.English}`);
    }
    return lang;
  };

  return {
    children: [
      {
        element: <Layout />,
        path: '/:lang',
        loader,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="home.breadcrumb"
              defaultMessage="Home"
              description="Home"
            />
          )
        },
        children: [...appPages]
      },
      {
        element: <LandingLayout />,
        path: '/:lang',
        loader,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="home.breadcrumb"
              defaultMessage="Home"
              description="Home"
            />
          )
        },
        children: [...landingPages]
      },
      {
        element: <LandingLayout classNames="chevrons" />,
        path: '/:lang',
        loader,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="home.breadcrumb"
              defaultMessage="Home"
              description="Home"
            />
          )
        },
        children: [...eventLandingPages]
      },

      {
        element: loggedIn ? <Layout /> : <LandingLayout />,
        path: '/:lang',
        loader,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="home.breadcrumb"
              defaultMessage="Home"
              description="Home"
            />
          )
        },
        children: [...footerPages]
      },
      // user will be re-routed to language
      {
        index: true,
        element: <LanguageRedirect />
      },
      {
        path: '*',
        element: <LoginRedirect />
      }
    ],
    errorElement: <ErrorPage />
  };
}
